import React, { Component } from 'react';
import './App.css';

import { Link, Route, Switch, BrowserRouter, HashRouter as Router } from 'react-router-dom';

import { 
    Box,
    Text
} from 'grommet';


import App2020 from './pages/App2020/App2020';
import App2021 from './pages/App2021/App2021';


class App extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {
        
        return (
            <Router>
                <Route exact path="/" component={() => 
                    <App2021 />
                }/>                    
                <Route exact path="/secret/:pass" component={() => 
                    <App2021 />
                }/>                    
                <Route exact path="/2020" component={() => 
                    <App2020 />
                }/>                    
                <Route exact path="/2021" component={() => 
                    <App2021 />
                }/>                 
            </Router>
        );
    }
}

export default App;
