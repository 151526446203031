import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./App2021.css";

import { 
    Box, 
    Grommet ,
    Text,
    ResponsiveContext,
    Anchor,
    Spinner
} from 'grommet';

import { 
    Close,
    CirclePlay 
} from 'grommet-icons';

import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';

import { motion, AnimatePresence } from "framer-motion"

import banana1 from '../../media/2021/banana1.png';
import banana2 from '../../media/2021/banana2.png';

import backyard from '../../media/2021/backyard.jpg';
import backyardGroup from '../../media/2021/backyardGroup.jpg';

import gif1 from '../../media/2021/gif1.gif';
import mbface from '../../media/2021/mbface.png';
import cloud from '../../media/2021/cloud.png';
import elephant1 from '../../media/2021/elephant1.png';

import wishImgRachel from '../../media/2021/wishImgRachel.png';
import wishImgKalindu from '../../media/2021/wishImgKalindu.png';
import wishImgAngela from '../../media/2021/wishImgAngela.png';
import wishImgSonila from '../../media/2021/wishImgSonila.jpg';
import wishImgCarmen from '../../media/2021/wishImgCarmen.jpeg';
import wishImgSoaad from '../../media/2021/wishImgSoaad.jpg';
import wishImgJana from '../../media/2021/wishImgJana.jpg';
import wishImgHanna from '../../media/2021/wishImgHanna.jpeg';
import wishImgVenkat from '../../media/2021/wishImgVenkat.jpg';

import Slider from 'infinite-react-carousel';

import useSound from 'use-sound';


import { images } from "./ImageData";

let wishAudioHanna = new Audio('https://audio-sounds-kdc.s3.amazonaws.com/wishAudioHanna.mp3');

let bobbingSize = {
    initial: {x:0, y:0},
    animate: obj => ({
        y: [0, obj.s ? obj.s : -12, 0],
        x:[0, 1, 0],
        transition: {
            delay: obj.i * 0.5,
            repeat: Infinity,
            duration:  1.6
        }
    })
}

let bobbing = {
    initial: {x:0, y:0},
    animate: i => ({
        y: [0, -12, 0],
        x:[0, 1, 0],
        transition: {
            delay: i * 0.5,
            repeat: Infinity,
            duration:  1.6
        }
    })
}

let bobbingCustom = {
    initial: {x:0, y:0},
    animate: obj => ({
        y: [obj.y, obj.y + (obj.s ? obj.s : -12), obj.y],
        x:[obj.x, obj.x+1, obj.x],
        transition: {
            delay: obj.i * 0.5,
            repeat: Infinity,
            duration:  1.6
        }
    })
}

const CoolCard = props => (
    <Box
        width= {{max:props.responsive === "small" ? "95%" : "900px"}}
        height={{min:"220px"}}
        background="white"
        pad={props.responsive === "small" ? "10px" : "medium"}
        round="5px"
        className="elevate-box"
        margin={{bottom:"15px"}}
        gap="small"
    >
        <Box
            width="auto"
            height="70px"
            direction="row"
            justify="between"
        >
            <Box
                width="auto"
                height="70px"
                direction="row"
                justify="start"
                gap="small"
                align="center"
            >
                <Text
                    size="35px"
                    color="rgb(42, 114, 114)"
                >
                    {props.name}
                </Text>
                {props.sound &&
                    <Box
                        onClick={() => {props.sound.play()}}
                    >
                        <CirclePlay color='rgb(114, 42, 42);' size='medium' />
                    </Box>
                    
                }
                {console.log(props.sound)}
            </Box>
            
            <img src={getRandomBool() ? banana1 : banana2} width="auto" height="100%" />
        </Box>
        <Box 
            direction={props.responsive === "small" ? "column" : getRandomBool() ? "row" : "row-reverse"}
            justify="start"
            align={props.responsive === "small" ? "start" : "center"}
            gap="25px"
        >
                
            <Box
                width={{min:props.responsive === "small" ? "100%" : "300px", max:props.responsive === "small" ? "100%" : "300px"}}
                height="auto"
                direction="row"
                justify="center"
                align="center"
                overflow="show"
            >
                <img src={props.img} width="100%" height="auto" />
            </Box>
            <Box
                width={{min:"80px"}}
                height="auto"
                direction="column"
                justify="start"
                align="start"
                margin={{top:"5px"}}
                gap="small"
            >
                {props.wish.map((item, i) =>  {
                    return <Box key={props.name + "-" + i}><Text color="rgb(121, 32, 32)" size="large">{item}</Text></Box>
                })}
            </Box>
        </Box>
    </Box>
    
)
const RandomBanana = props => (
    <motion.div
        style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
        custom={{x:props.multiplier * getRandomX(0.1), y:getRandomInt(25, 60), i:((getRandomInt(1, 20) * 1.0) / 10.0)}}
        initial="initial"
        animate="animate"
        variants={bobbingCustom}
        
    >
        <img src={getRandomBool() ? banana1 : banana2} width= {getRandomInt(30, 45) + "px"} height="auto"/>
    </motion.div>
    
)

let getRandomBool = () => {
    let min = 0;
    let max = 100;

    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min > 50;
}

let getRandomX = (scale) => {
    let min = 60 * scale;
    let max = window.screen.width * scale;

    min = Math.ceil(min);
    max = Math.floor(max);
    let result = Math.floor(Math.random() * (max - min + 1)) + min;

    return result;
}

let getRandomInt = (min, max) => {

    min = Math.ceil(min);
    max = Math.floor(max);
    let result = Math.floor(Math.random() * (max - min + 1)) + min;

    return result;
}

class App2021 extends Component {

    _isMounted = false;
    
    constructor(props) {
        
        super(props);

        this.interval = null;
        this.start = 'April 02 2020 00:00:00 UTC-0500';
        this.end = 'April 02 2021 00:00:00 UTC-0500';
        this.offset = getRandomInt(0, images.length - 1);

        this.state = {
            total:0,
            percentage:0,
            days:0,
            hours:0,
            minutes:0,
            seconds:0,
            cardOpen:false,
            galleryIndex: 0,
            galleryMoveDir: 0,
            loading:false,
            hacks:false
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.interval);
    }

    componentDidMount() {
        this._isMounted = true;
        
        if (this._isMounted) {
            
            ////////// TEMP /////////////////
            // this.setState(this.props.match.params);
            // let curr = new Date();
            // curr.setSeconds(curr.getSeconds() - 5);
            // this.end = curr.toString();
            ////////////////////////////////////            

            this.upateTimeRemaining();
            this.interval = setInterval(() => this.upateTimeRemaining(), 1000);

            console.log(wishAudioHanna);
        }
    }

    upateTimeRemaining(){

        const progress = Date.parse(this.end) - Date.parse(new Date());
        const seconds = Math.floor( (progress/1000) % 60 );
        const minutes = Math.floor( (progress/1000/60) % 60 );
        const hours = Math.floor( (progress/(1000*60*60)) % 24 );
        const days = Math.floor( progress/(1000*60*60*24) );

        const total = Date.parse(this.end) - Date.parse(this.start);
        const percentage = 1 - (progress/total);

        let updated =  {
            total,
            percentage,
            days,
            hours,
            minutes,
            seconds
        };

        if (percentage >= 1) {
            updated = {
                total: updated.total,
                percentage: updated.percentage,
                days:0,
                hours:0,
                minutes:0,
                seconds:0
            }

            //this.testTime();
            
            clearInterval(this.interval);
        }

        this.setState(updated);
    }

    testTime() {

        //https://worldtimeapi.org/api/timezone/America/Toronto
        fetch('https://worldtimeapi.org/api/ip')
            .then(response => response.json())
            .then(data => {

                let curr = new Date();
                let fetched = new Date(data.datetime)

                let progress = Date.parse(curr) - Date.parse(fetched);

                console.log(Math.abs(progress));

                this.setState({loading: false, hacks: Math.abs(progress) > 480000})
            })
            .catch(err => {
                location.reload(); 
            });

    }

    render() {
        const customTheme = {
            global: {
                colors: {
                    custom: "#999"
                },
                focus: {
                    border: {
                        color: "none"
                    }
                }
              
            }
        };


        const countDown = 
        <ResponsiveContext.Consumer>
            {responsive =>
                <Box
                    width="100vw"
                    height="100vh"
                    direction="column"
                    justify="center"
                    align="center"
                    gap="large"
                    pad="20px"
                >
                    <Box
                        direction="row"
                        justify="center"
                        align="center"
                    >
                        <Text
                            size="40px"
                            textAlign="center"
                        >
                            LOL! It's not your Birthday yet, SCRUB!
                        </Text>
                    </Box>
                    <Box
                        width={responsive === "small" ? "95%" : "700px"}
                        direction="row"
                        justify="center"
                        align="center"
                        gap="small"
                    >
                        <Box
                            direction="column"
                            width={{min:"70px"}}
                            height="110px"
                            align="center"
                            justify="between"
                            pad={{
                                top:"10px",
                                bottom:"10px",
                                left:"5px",
                                right:"5px"
                            }}
                            border="all"
                            round="10px"
                        >
                            <Text size="50px">{this.state.days}</Text>
                            <Text size="15px">days</Text>
                        </Box> 
                        <Text size="25px">:</Text>                                
                        <Box
                            direction="column"
                            width="70px"
                            height="110px"
                            align="center"
                            justify="between"
                            pad={{
                                top:"10px",
                                bottom:"10px",
                                left:"5px",
                                right:"5px"
                            }}
                            border="all"
                            round="10px"
                        >
                            <Text size="50px">{this.state.hours}</Text>
                            <Text size="15px">hours</Text>
                        </Box> 
                        <Text size="25px">:</Text>                                
                        <Box
                            direction="column"
                            width="70px"
                            height="110px"
                            align="center"
                            justify="between"
                            pad={{
                                top:"10px",
                                bottom:"10px",
                                left:"5px",
                                right:"5px"
                            }}
                            border="all"
                            round="10px"
                        >
                            <Text size="50px">{this.state.minutes}</Text>
                            <Text size="15px">minutes</Text>
                        </Box> 
                        <Text size="25px">:</Text>                                
                        <Box
                            direction="column"
                            width="70px"
                            height="110px"
                            align="center"
                            justify="between"
                            pad={{
                                top:"10px",
                                bottom:"10px",
                                left:"5px",
                                right:"5px"
                            }}
                            border="all"
                            round="10px"
                        >
                            <Text size="50px">{this.state.seconds}</Text>
                            <Text size="15px">seconds</Text>
                        </Box> 
                    </Box>
                    <Box
                        direction="row"
                        width="600px"
                        height="20px"
                        border="all"
                        round="5px"
                    >
                        <Box
                            height="100%"
                            width={(this.state.percentage)*100 + "%"}
                            background="#292929"
                            round="5px"
                        >
                        </Box>
                    </Box>
                    
                </Box>
            }
        </ResponsiveContext.Consumer>;

        let home = 
        <ResponsiveContext.Consumer>
            {responsive =>
                <Box
                    background="#f6bd60"
                    pad="large"
                    width="100vw"
                    height="100vh"
                    direction="column"
                    justify="start"
                    align="start"
                    overflow="hidden"
                    gap="small"
                >
                    <Box
                        width="100%"
                        height="auto"
                        direction="row"
                        justify="between"
                        align="center"
                        pad={{bottom:"5px"}}
                    >
                        <Box
                            direction="row"
                            gap="small"
                        >
                            <motion.div
                                custom={{i:0.5, s: responsive === "small" ? -5: -12}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                            >
                                <Text
                                    size={responsive === "large" ? "60px" : "30px"}
                                    color="white"
                                    className="elevate"
                                >
                                    HAPPY
                                </Text>
                            </motion.div>
                            <motion.div
                                custom={{i:0.9, s: responsive === "small" ? -5: -12}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                            >
                                <Text
                                    size={responsive === "large" ? "60px" : "30px"}
                                    color="white"
                                    className="elevate"
                                >
                                    BIRTHDAY
                                </Text>
                            </motion.div>
                        </Box>
                        <Box
                            direction="row"
                            gap="small"
                        >
                            <motion.div
                                custom={{i:1.1, s: responsive === "small" ? -5: -12}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                            >
                                <Text
                                    size={responsive === "large" ? "60px" : "30px"}
                                    color="white"
                                    className="elevate"
                                >
                                    25
                                </Text>
                            </motion.div>
                        </Box>
                        
                    </Box>
                    <Box
                        width={{min:responsive === "small" ? "150px" : "250px"}}
                        height={{min:responsive === "small" ? "10px" : "13px"}}
                        round="2px"
                        margin={{bottom:"5px"}}
                        
                    >
                        <motion.div
                            style={{ width:"100%", height:"100%", background:"-webkit-linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)", borderRadius:"4px"}}
                            animate={{ y: [-0, responsive === "small" ? -5: -13, -0] }}
                            transition={{ duration: 1.6, repeat: Infinity }}
                            className="elevate-box"
                        >
                        </motion.div>
                    </Box>
                    <Box
                        width="100%"
                        height="auto"
                        flex="grow"
                        direction={responsive === "small" ? "column" : "row"}
                        justify="center"
                        align="center"
                        onClick={() => {console.log(this.state); this.setState({cardOpen: !this.state.cardOpen}, () => {console.log(this.state);})}}
                        gap="medium"
                    >
                        {responsive !== "small" && <motion.div
                            style={{ width: "auto", height:"auto", zIndex:"98", position:"absolute", left: 0 }}
                            custom={{x: responsive === "large" ? 180 : 130, y:0, i:1.4}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                        >
                            <img src={mbface} width= {responsive === "large" ? "300px" : "200px"} height="auto"/>
                        </motion.div>}
                        {responsive !== "small" && <motion.div
                            style={{ width: "auto", height:"auto", zIndex:"98", position:"absolute", left: 0 , opacity:0.3}}
                            custom={{x: responsive === "large" ? 110 : 70, y:100, i:0.84}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                        >
                            <img src={cloud} width= {responsive === "large" ? "300px" : "200px"} height="auto"/>
                        </motion.div>}
                        {responsive !== "small" && <motion.div
                            style={{ width: "auto", height:"auto", zIndex:"98", position:"absolute", left: 0 , opacity:0.3, WebkitTransform: "scaleX(-1)", transform:"scaleX(-1)"}}
                            custom={{x: responsive === "large" ? 210 : 160, y:80, i:1.14}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                        >
                            <img src={cloud} width= {responsive === "large" ? "340px" : "220px"} height="auto" style={{ WebkitTransform: "scaleX(-1)", transform:"scaleX(-1)"}}/>
                        </motion.div>}
                        <motion.div
                            style={{ width: "auto", height:"auto", zIndex:"100", position:"absolute" }}
                            custom={{x:responsive === "small" ? -120 : -350, y:responsive === "small" ? -150 : -180, i:0.4}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                        >
                            <img src={banana1} width= "30px" height="auto"/>
                        </motion.div>
                        <motion.div
                            style={{ width: "auto", height:"auto", zIndex:"100", position:"absolute" }}
                            custom={{x:responsive === "small" ? -130 : -300, y:responsive === "small" ? 150 : 180, i:0.4}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                        >
                            <img src={elephant1} width= {responsive === "large" ? "120px" : "80px"} height="auto"/>
                        </motion.div>
                        <motion.div
                            style={{ width: responsive === "large" ? "500px" : "315px", height: responsive === "large" ? "650px" : "410px", zIndex:"99" }}
                            custom={0.5}
                            initial="initial"
                            animate="animate"
                            variants={bobbing}
                            className="elevate-box birthday"
                            
                        >
                        </motion.div>
                        <motion.div
                            style={{ width: "auto", height:"auto", zIndex:"100", position:"absolute" }}
                            custom={{x:responsive === "small" ? 120 : 350, y:responsive === "small" ? 150 : 180, i:0.78}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                            
                        >
                            <img src={banana2} width= "40px" height="auto"/>
                        </motion.div>

                        {responsive !== "small" && 
                            <motion.div
                                style={{ width: "auto", height:"auto", zIndex:"98", position:"absolute", right: 0 }}
                                custom={{x:responsive === "large" ? -120: -80, y:responsive === "large" ? -160: -200, i:0.18}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingCustom}
                                
                            >
                                <img src={gif1} width= {responsive === "large" ? "360px": "280px"} height="auto"/>
                            </motion.div>
                        }
                        

                    </Box>
                    <Box
                        width="100%"
                        height="auto"
                        direction="row"
                        justify="between"
                        align="center"
                        pad={{top:"5px"}}
                    >
                        <Box
                            direction="row"
                            gap="small"
                        >
                            <motion.div
                                custom={{i:0.6, s: responsive === "small" ? -5: -12}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                            >
                                <Text
                                    size={responsive === "large" ? "60px" : "30px"}
                                    color="white"
                                    className="elevate"
                                >
                                    2021
                                </Text>
                            </motion.div>
                        </Box>
                        <Box
                            direction="row"
                            gap="small"
                        >
                            <motion.div
                                custom={{i:0.8, s: responsive === "small" ? -5: -12}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                            >
                                <Text
                                    size={responsive === "large" ? "60px" : "30px"}
                                    color="white"
                                    className="elevate"
                                >
                                    APRIL
                                </Text>
                            </motion.div>
                            <motion.div
                                custom={{i:1.1, s: responsive === "small" ? -5: -12}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                            >
                                <Text
                                    size={responsive === "large" ? "60px" : "30px"}
                                    color="white"
                                    className="elevate"
                                >
                                    2
                                </Text>
                            </motion.div>
                        </Box>

                    </Box>
                    
                    
                </Box>
            }
        </ResponsiveContext.Consumer>;

        let card =
        <ResponsiveContext.Consumer>
            {responsive =>
                <Box
                    background="#f6bd60"
                    pad="large"
                    width="100vw"
                    height={{min:"100vh"}}
                    direction="column"
                    justify="start"
                    align="start"
                    className="can-overflow"
                    
                >

                    <motion.div
                        style={{ width: "auto", height:"auto", zIndex:"102", position:"absolute" }}
                        custom={{x:0, y:0, i:0.78}}
                        initial="initial"
                        animate="animate"
                        variants={bobbingCustom}
                        className="hover"
                        onClick={() => {console.log(this.state); this.setState({cardOpen: !this.state.cardOpen}, () => {console.log(this.state);})}}
                    >
                        <Close color='plain' size='large' /> 
                    </motion.div>

                    <Box
                        width="100%"
                        height="auto"
                        direction="row"
                        justify="center"
                        align="start"
                    >
                        <motion.div
                            style={{ width: "87%", height:"auto", zIndex:"100" }}
                            custom={{x:0, y:0, i:0.78, s:-2}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                        >
                            <img src={backyard} width="100%" height="auto" />
                        </motion.div>
                    </Box>

                    <Box
                        background="#f6bd60"
                        pad="large"
                        width="100vw"
                        height={{min:"100vh"}}
                        direction="row"
                        justify="start"
                        align="start"
                    >

                        {responsive !== "small" &&
                            <Box
                                width="200px"
                                height="auto"
                                direction="column"
                                justify="start"
                                align="center"
                                gap="50px"
                            >
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:25, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[0]} width= "150px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:25, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[24]} width= "250px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[3]} width= "250px" height="auto"/>
                                </motion.div>
                                
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[26]} width= "120px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[27]} width= "250px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[28]} width= "150px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[30]} width= "150px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[5]} width= "150px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[7]} width= "100px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[8]} width= "250px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[9]} width= "100px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:getRandomX(0.1), y:55, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[10]} width= "150px" height="auto"/>
                                </motion.div>
                                
                                

                            </Box>
                        }

                        <Box
                            width="auto"
                            height="auto"
                            direction="column"
                            justify="center"
                            align="center"
                            overflow="auto"
                            wrap={true}
                            gap="small"
                            overflow="hidden"
                            flex="grow"
                        >
                            
                            <motion.div
                                custom={{i:0.8, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} name="Rachel" img={wishImgRachel} wish={["Happy Birthday, Mahima!","You’re another year older & you’ve somehow gotten both hotter and cooler at the same time! I wish you all the love, happiness & peace in the world (all of which you deserve). I hope you had a spectacular day and I hope we were finally able to surprise the unsurpriseable one! LOL", " Love,","-Rachel"]} />
                            </motion.div>
                            <motion.div
                                custom={{i:0.55, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} name="Angela" img={wishImgAngela} wish={["Happy Birthday Mahima! (I am not adding a , because I am not Rachel)", "I was hoping this year we could celebrate the big 25 in person and do a big party, but hope you liked the little surprises we prepared for you. I am proud to see you rock the world, shine and glow because you deserve it! I know you will keep being the boss bitch you are and accomplishing all your goals.","Love you and I wish you nothing but good things <3"]} />
                            </motion.div>
                            <motion.div
                                custom={{i:1.2, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} name="Sonila" img={wishImgSonila} wish={["Happy Big 25 Mahimmerzz!! To my favourite person to car karaoke with to ae dil hai mushkil, the person down to take a spontaneous trip to manali and live our YJHD dream, my walking inspo buddy, and hot girl thottum queeeen. Theres so much more you bring to my life and I will always make sure to be there for you.", "Love you and wish you many more years of haseen jeewani because remember, jeewani phir nahi ani :)"]} />
                            </motion.div>
                            <motion.div
                                custom={{i:1, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} name="Kalindu" img={wishImgKalindu} wish={["Happy Birthday MAHIMA!!!!!", "Thank you for always being there for me and everyone. You always take your time to take care of everyone. You have the biggest heart and I appreciate everything you do for everyone. I hope you have the best year ever with lots and lots of floofy frens!", "New year! New you!", ":)))"]} />
                            </motion.div>
                            <motion.div
                                custom={{i:0.2, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} name="Carmen" img={wishImgCarmen} wish={["Happy birthday to the sweetest, most caring human bean!", "Thanks for being there through it all!", "I miss dancing with you! Hope we can hike and drink in the park (or beach now hehe) together soon!", "Wishing you infinite happiness and hope you enjoy your special day ~", "Keep rockin it bbg! 😘 Quarter life crisis who??? Don't know her 💁‍♀️", "Love you to the 👽s & back,", "Carmen"]} />
                            </motion.div>
                            <motion.div
                                custom={{i:1.2, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} name="Soaad" img={wishImgSoaad} wish={[
                                    "With you becoming 25 today, feel like a great way to start it off is with a nice poem. Hope you enjoy it!", 
                                    <strong>Every One of Your Birthdays</strong>,
                                    <Box
                                        direction="column"
                                    >
                                        <Text>Every one of your birthdays</Text>
                                        <Text>is a gift to the rest of us</Text>
                                        <Text>who have had the pleasure</Text>
                                        <Text>of having you in our lives</Text>
                                        <Text>for another year.</Text>
                                    </Box>,
                                    <Box
                                        direction="column"
                                    >
                                        <Text>Every one of your birthdays</Text>
                                        <Text>brings joyful memories</Text>
                                        <Text>of your bright smile</Text>
                                        <Text>and your love of life.</Text>
                                    </Box>,
                                    <Box
                                        direction="column"
                                    >
                                        <Text>Every one of your birthdays</Text>
                                        <Text>reminds us of your sensitivity</Text>
                                        <Text>and service to others,</Text>
                                        <Text>and other delights</Text>
                                        <Text>too numerous to name</Text>
                                        <Text>that we are gifted with</Text>
                                        <Text>just by knowing you.</Text>
                                    </Box>,
                                    <Box
                                        direction="column"
                                    >
                                        <Text>Happy Birthday</Text>
                                        <Text>to one in a million.</Text>
                                        <Text>May you, and we</Text>
                                        <Text>enjoy many more.</Text>
                                    </Box>,
                                    <Box
                                        direction="column"
                                    >
                                        <Text>By Joanna Fuchs</Text>
                                    </Box>,
                                ]} />
                            </motion.div>
                            <motion.div
                                custom={{i:0.64, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} name="Janarthanan" img={wishImgJana} wish={[
                                    "Hi Maximadoo, thank you for turning 25.", 
                                    "What follows are some undeniable facts.",
                                    "It is your birthday, you are now 25.",
                                    "You lived in Scarborough last year and you live in Ajax now.",
                                    "I hope you have a prosperous and wonderful year ahead. I don’t have any pictures of us TGT, so here is a picture of your two favorite people instead",
                                    "AKA your Orphan buddies",
                                ]} />
                            </motion.div>
                            <motion.div
                                custom={{i:1.64, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} 
                                    name="Hanna" 
                                    img={wishImgHanna} 
                                    wish={[
                                        "Happiest of birthdays Mahima!!", 
                                        " I’m so incredibly grateful to have met you this year <3 from our tea spilling sessions and fun nights playing games to our random deep talks, I’ve grown to care for you so so much and now you’re stuck with me so hah. You’re one of the most thoughtful, caring, and strongest women I know, and I’m wishing you endless love and happiness this year.",
                                        "The bidabibabibibobo to your clownery <3 ",
                                    ]}   
                                    sound={wishAudioHanna}                              
                                />
                            </motion.div>
                            <motion.div
                                custom={{i:0.14, s: responsive === "small" ? +3: +8}}
                                initial="initial"
                                animate="animate"
                                variants={bobbingSize}
                                style={{ zIndex:"101"}}
                            >

                                <CoolCard responsive={responsive} 
                                    name="Venkat" 
                                    img={wishImgVenkat} 
                                    wish={[
                                        "Happy Birthday Mahima!!", 
                                        "You’re basically an auntie now. Soon, you’ll be a grandma :)).",
                                        "You’ve had two birthdays in covid (I have a little bit of experience there), but as soon as we’re done, we’ll party. I’m sure you’ve been wanting to turn up. Hopefully, we can make the best of 25!!",
                                    ]} 
                                />
                            </motion.div>
                            
                        </Box> 
                        {responsive !== "small" &&
                            <Box
                                width="200px"
                                height="100%"
                                direction="column-reverse"
                                justify="start"
                                align="center"
                                gap="50px"
                                margin={{bottom:"55px"}}
                            >
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[25]} width= "150px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[1]} width= "250px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[4]} width= "320px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[2]} width= "180px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[36]} width= "180px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[30]} width= "180px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[29]} width= "180px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[22]} width= "180px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[19]} width= "180px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[18]} width= "180px" height="auto"/>
                                </motion.div>
                                <RandomBanana multiplier={-1} />
                                <motion.div
                                    style={{ width: "auto", height:"auto", zIndex:"100", position:"relative" }}
                                    custom={{x:-1*getRandomX(0.1), y:80, i:getRandomInt(0,15) / 20.0}}
                                    initial="initial"
                                    animate="animate"
                                    variants={bobbingCustom}
                                    
                                >
                                    <img src={images[17]} width= "180px" height="auto"/>
                                </motion.div>
                            </Box>
                            
                        }

                    </Box>
                    <Box
                        width="100%"
                        height="auto"
                        direction="row"
                        justify="center"
                        align="start"
                        margin={{bottom:"20px"}}
                    >
                        <motion.div
                            style={{ width: "87%", height:"auto", zIndex:"100" }}
                            custom={{x:0, y:0, i:0.78, s:-2}}
                            initial="initial"
                            animate="animate"
                            variants={bobbingCustom}
                        >
                            <img src={backyardGroup} width="100%" height="auto" />
                        </motion.div>
                    </Box>
                    
                    <Box
                        width="100%"
                        height="auto"
                        direction="row"
                        justify="center"
                        align="center"
                    >
                        <Box
                            width={responsive === "small" ? "100%" : "45%"}
                            height="auto"
                            direction="row"
                            justify="center"
                            align="center"
                        >
                            <Slider dots adaptiveHeight className="image-gallery" initialSlide={this.offset}>
                                {images.map((image, i) => {
                                    return <img key={"image-gallery-" + i} src={image} width="85%" height="auto"/>
                                })}
                            </Slider>
                        </Box>
                    </Box>
                </Box>
            }
        </ResponsiveContext.Consumer>;

        let hacks = 
        <Box
            width="100vw"
            height="100vh"
            direction="row"
            justify="center"
            align="center"
        >
            <Text size="40px" textAlign="center">Nice try, NERD!</Text>
        </Box>

        let loading = 
        <Box
            width="100vw"
            height="100vh"
            direction="row"
            justify="center"
            align="center"
            gap="small"
        >
            <Spinner size="medium" color="black" />
            <Text size="40px" textAlign="center"> LOADING ...</Text>
        </Box>
 

        return (
            <Grommet theme={customTheme}>

                <motion.div
                    style={{ width: "100%", height:"auto", position:"absolute", display: "flex", flexDirection:"row", justifyContent:"center" }}
                    custom={true}
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden:{
                            opacity:0
                        },
                        visible: show => ({
                            opacity:show ? 1 : 0
                        })
                    }}
                    
                >
                    <Anchor href="/#/2020"><Text>2020</Text></Anchor> 
                </motion.div>

                {this.state.percentage < 1 && <motion.div
                    style={{ width: "auto", height:"auto" }}
                    custom={this.state.percentage < 1}
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden:{
                            opacity:0
                        },
                        visible: show => ({
                            opacity:show ? 1 : 0
                        })
                    }}
                    
                >
                    {countDown}
                </motion.div>}
                
                {this.state.percentage >= 1 && !this.state.cardOpen && <motion.div
                    style={{ width: "auto", height:"auto" }}
                    custom={this.state.percentage >= 1 && !this.state.cardOpen}
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden:{
                            opacity:0
                        },
                        visible: show => ({
                            opacity:show ? 1 : 0
                        })
                    }}
                    
                >
                    {this.state.loading ? 
                        loading
                    : 
                        this.state.hacks ? hacks : home
                    }
                </motion.div>}
                {this.state.cardOpen && <motion.div
                    style={{ width: "auto", height:"auto" }}
                    custom={this.state.cardOpen}
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden:{
                            opacity:0
                        },
                        visible: show => ({
                            opacity:show ? 1 : 0
                        })
                    }}
                    
                >
                    {this.state.loading ? 
                        loading
                    : 
                        this.state.hacks ? hacks : card
                    }
                </motion.div>}


                {/* {this.state.percentage >= 1 && <motion.div
                    style={{ width: "auto", height:"auto" }}
                    custom={this.state.percentage >= 1}
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden:{
                            opacity:0
                        },
                        visible: show => ({
                            opacity:show ? 1 : 0
                        })
                    }}
                    
                >
                    <Box
                        width="100vw"
                        height="100vh"
                        direction="row"
                        justify="center"
                        align="center"
                    >
                        <Text size="40px" textAlign="center">Nice try, NERD!</Text>
                    </Box>
                </motion.div>} */}

                

                {/* {this.state.percentage < 1 && countDown}
                {this.state.percentage >= 1 && !this.state.cardOpen && home}
                {this.state.cardOpen && card} */}
                
            </Grommet>
            
        );
    }
    
}

export default withRouter(App2021);