import React, { Component } from 'react';
import './App2020.css';

import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import {config} from 'react-spring/renderprops';
import { IconButton } from '@material-ui/core';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';


import main from '../../media/2020/main.png'
import fl1 from '../../media/2020/fl1.png'
import fl2 from '../../media/2020/fl2.png'
import hat from '../../media/2020/hat.png'
import cake from '../../media/2020/cake.png'
import age from '../../media/2020/age.png'
import love from '../../media/2020/love.gif'
import love2 from '../../media/2020/love2.gif'
import cloud from '../../media/2020/cloud.png'
import back from '../../media/2020/back.png'
import sky from '../../media/2020/sky.png'

import blueb from '../../media/2020/blueb.png'
import greenb from '../../media/2020/greenb.png'
import limeb from '../../media/2020/limeb.png'
import orangeb from '../../media/2020/orangeb.png'
import pinkb from '../../media/2020/pinkb.png'
import purpleb from '../../media/2020/purpleb.png'
import purpleb2 from '../../media/2020/purpleb2.png'
import redb from '../../media/2020/redb.png'
import salmonb from '../../media/2020/salmonb.png'
import tealb from '../../media/2020/tealb.png'
import tealb2 from '../../media/2020/tealb2.png'
import yellowb from '../../media/2020/yellowb.png'

// letters
import h1 from '../../media/2020/letters/h1.png'
import h2 from '../../media/2020/letters/h2.png'
import h3 from '../../media/2020/letters/h3.png'
import a1 from '../../media/2020/letters/a1.png'
import a2 from '../../media/2020/letters/a2.png'
import a3 from '../../media/2020/letters/a3.png'
import p2 from '../../media/2020/letters/p2.png'
import p3 from '../../media/2020/letters/p3.png'
import p4 from '../../media/2020/letters/p4.png'
import y1 from '../../media/2020/letters/y1.png'
import y2 from '../../media/2020/letters/y2.png'
import b1 from '../../media/2020/letters/b1.png'
import b2 from '../../media/2020/letters/b2.png'
import i1 from '../../media/2020/letters/i1.png'
import r1 from '../../media/2020/letters/r1.png'
import r2 from '../../media/2020/letters/r2.png'
import t1 from '../../media/2020/letters/t1.png'
import t2 from '../../media/2020/letters/t2.png'
import t3 from '../../media/2020/letters/t3.png'
import d1 from '../../media/2020/letters/d1.png'


// WISHES
import wishKali from '../../media/2020/wishes/wishKalindu.png'
import wishFahad from '../../media/2020/wishes/wishFahad.png'
import wishAngela from '../../media/2020/wishes/wishAngela.png'
import wishJana from '../../media/2020/wishes/wishJana.png'
import wishSonila from '../../media/2020/wishes/wishSonila.png'
import wishKathryn from '../../media/2020/wishes/wishKathryn.png'
import wishAhmad from '../../media/2020/wishes/wishAhmad.gif'
import wishCarmen from '../../media/2020/wishes/wishCarmen.png'
import wishVenky from '../../media/2020/wishes/wishVenky.png'
import wishAva from '../../media/2020/wishes/wishAva.png'
import wishFaizan from '../../media/2020/wishes/wishFaizan.gif'
import wishFreshta from '../../media/2020/wishes/wishFreshta.png'
import wishRach from '../../media/2020/wishes/wishRach.png'
import wishSoda from '../../media/2020/wishes/wishSoda.png'
import wishJoy from '../../media/2020/wishes/wishJoy.png'
import wishMomDad from '../../media/2020/wishes/wishMomDad.png'
import wishSaatvik from '../../media/2020/wishes/wishSaatvik.png'
import wishNanaji from '../../media/2020/wishes/wishNanaji.png'


// GALLERY
import g1 from '../../media/2020/gallery/1.jpg'
import g2 from '../../media/2020/gallery/2.jpg'
import g3 from '../../media/2020/gallery/3.jpg'
import g4 from '../../media/2020/gallery/4.jpg'
import g5 from '../../media/2020/gallery/5.jpg'
import g6 from '../../media/2020/gallery/6.JPG'
import g7 from '../../media/2020/gallery/7.JPG'
import g8 from '../../media/2020/gallery/8.JPG'
import g9 from '../../media/2020/gallery/9.JPG'
import g10 from '../../media/2020/gallery/10.JPG'
import g11 from '../../media/2020/gallery/11.JPG'
import g12 from '../../media/2020/gallery/12.JPG'
import g13 from '../../media/2020/gallery/13.JPG'
import g14 from '../../media/2020/gallery/14.jpg'
import g15 from '../../media/2020/gallery/15.JPG'
import g16 from '../../media/2020/gallery/16.JPG'
import g17 from '../../media/2020/gallery/17.JPG'
import g18 from '../../media/2020/gallery/18.jpg'
import g19 from '../../media/2020/gallery/19.jpg'
import g20 from '../../media/2020/gallery/20.jpg'
import g21 from '../../media/2020/gallery/21.jpg'
import g22 from '../../media/2020/gallery/22.jpg'
import g23 from '../../media/2020/gallery/23.jpg'
import g24 from '../../media/2020/gallery/24.jpg'
import g25 from '../../media/2020/gallery/25.jpg'
import g26 from '../../media/2020/gallery/26.JPG'
import g27 from '../../media/2020/gallery/27.jpg'
import g28 from '../../media/2020/gallery/28.jpg'
import g29 from '../../media/2020/gallery/29.jpg'
import g30 from '../../media/2020/gallery/30.jpg'
import g31 from '../../media/2020/gallery/31.jpg'
import g32 from '../../media/2020/gallery/32.jpg'
import g33 from '../../media/2020/gallery/33.jpg'
import g34 from '../../media/2020/gallery/34.jpg'
import g35 from '../../media/2020/gallery/35.JPG'
import g36 from '../../media/2020/gallery/36.jpg'
import g37 from '../../media/2020/gallery/37.JPG'
import g38 from '../../media/2020/gallery/38.jpg'
import g39 from '../../media/2020/gallery/39.jpeg'
import g40 from '../../media/2020/gallery/40.jpg'
import g41 from '../../media/2020/gallery/41.jpg'
import g42 from '../../media/2020/gallery/42.jpg'
import g43 from '../../media/2020/gallery/43.jpg'
import g44 from '../../media/2020/gallery/44.jpg'
import g45 from '../../media/2020/gallery/45.jpg'
import g46 from '../../media/2020/gallery/46.jpg'
import g47 from '../../media/2020/gallery/47.jpg'
import g48 from '../../media/2020/gallery/48.jpg'
import g49 from '../../media/2020/gallery/49.jpg'
import g50 from '../../media/2020/gallery/50.jpg'
import g51 from '../../media/2020/gallery/51.jpg'
import g52 from '../../media/2020/gallery/52.jpg'
import g53 from '../../media/2020/gallery/53.jpg'
import g54 from '../../media/2020/gallery/54.jpg'
import g55 from '../../media/2020/gallery/55.jpg'
import g56 from '../../media/2020/gallery/56.jpg'
import g57 from '../../media/2020/gallery/57.jpg'
import g58 from '../../media/2020/gallery/58.jpg'
import g59 from '../../media/2020/gallery/59.jpg'
import g60 from '../../media/2020/gallery/60.jpg'
import g61 from '../../media/2020/gallery/61.jpg'
import g62 from '../../media/2020/gallery/62.jpg'
import g63 from '../../media/2020/gallery/63.jpg'
import g64 from '../../media/2020/gallery/64.jpg'
import g65 from '../../media/2020/gallery/65.jpg'
import g66 from '../../media/2020/gallery/66.jpg'
import g67 from '../../media/2020/gallery/67.jpg'
import g68 from '../../media/2020/gallery/68.jpg'
import g69 from '../../media/2020/gallery/69.jpg'
import g70 from '../../media/2020/gallery/70.png'
import g71 from '../../media/2020/gallery/71.png'
import g72 from '../../media/2020/gallery/72.jpg'
import g73 from '../../media/2020/gallery/73.jpg'
import g74 from '../../media/2020/gallery/74.jpg'
import g75 from '../../media/2020/gallery/75.jpg'
import g76 from '../../media/2020/gallery/76.jpg'
import g77 from '../../media/2020/gallery/77.jpg'
import g78 from '../../media/2020/gallery/78.jpg'
import g79 from '../../media/2020/gallery/79.jpg'
import g80 from '../../media/2020/gallery/80.jpg'
import g81 from '../../media/2020/gallery/81.jpg'
import g82 from '../../media/2020/gallery/82.jpg'
import g83 from '../../media/2020/gallery/83.jpg'
import g84 from '../../media/2020/gallery/84.jpg'
import g85 from '../../media/2020/gallery/85.jpg'
import g86 from '../../media/2020/gallery/86.jpg'
import g87 from '../../media/2020/gallery/87.jpeg'
import g88 from '../../media/2020/gallery/88.jpeg'
import g89 from '../../media/2020/gallery/89.jpeg'
import g90 from '../../media/2020/gallery/90.jpeg'

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
          play: false,
          pause: true,
        }
        this.url = "https://audio-sounds-kdc.s3.amazonaws.com/bday1.wav";
        this.audio = new Audio(this.url);
        this.audio.addEventListener('ended', function () {
            this.currentTime = 0;
            this.play();
        }, false);
    }
    
    play = () => {
        this.setState({ play: true, pause: false })
        this.audio.play();
    }
      
    pause = () => {
        this.setState({ play: false, pause: true })
        this.audio.pause();
    }
      
    rand = () => {
        return (Math.random() * (-0.1 - 0.5) + 0.5).toFixed(4)
    }
    
    render() {
        const ontop = {
            position:'fixed',
            margin: '25px'
        }

        var npages = 15;

        return (
            <Parallax ref={ref => (this.parallax = ref)} pages={npages} config={config.molasses}>

                <ParallaxLayer 
                    factor={npages * 2} 
                    offset={0} 
                    speed={1} 
                    style={
                        { 
                            background: 'rgb(137,253,255)',
                            background: 'linear-gradient(180deg, rgba(137,253,255,1) 0vw, rgba(255,217,135,1) 100vw)' 
                        }
                    }
                />
                
                {/* CLOUDS */}
                <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={2.6} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={4.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={4.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={4.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={3.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={4.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={5.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={5.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={5.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={4.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={5.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={6.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={6.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={6.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={5.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={6.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={7.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={7.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={7.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={6.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={7.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={8.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={8.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={8.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={7.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={8.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={9.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={8.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={10.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={11.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={12.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>

                {/* ------------------------ */}
                <ParallaxLayer offset={13.65} speed={0.8} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '55vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '15vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.35} speed={0.5} style={{ opacity: 0.1 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '70vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '40vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.1} speed={0.2} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '10vw' }} />
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '75vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.6} speed={-0.1} style={{ opacity: 0.4 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '60vw' }} />
                <img src={cloud} style={{ display: 'block', width: '25vw', marginLeft: '30vw' }} />
                <img src={cloud} style={{ display: 'block', width: '10vw', marginLeft: '80vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.9} speed={0.3} style={{ opacity: 0.6 }}>
                <img src={cloud} style={{ display: 'block', width: '20vw', marginLeft: '5vw' }} />
                <img src={cloud} style={{ display: 'block', width: '15vw', marginLeft: '65vw' }} />
                </ParallaxLayer>
                
                {/* BALLOONS */}
                
                <ParallaxLayer offset={0.65} speed={0.4} style={{ opacity: 0.8 }}>
                <img src={orangeb} className='floating1' style={{ display: 'block', width: '9vw', marginLeft: '5vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={0.3} style={{ opacity: 0.8 }}>
                <img src={redb} className='floating2'  style={{ display: 'block', width: '8vw', marginLeft: '85vw', transform: 'rotate(-9deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.05} speed={-0.2} style={{ opacity: 0.8 }}>
                <img src={purpleb} className='floating1'  style={{ display: 'block', width: '6vw', marginLeft: '75vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={1.6} speed={-0.2} style={{ opacity: 0.8 }}>
                <img src={limeb} className='floating1'  style={{ display: 'block', width: '6vw', marginLeft: '90vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                {/* HAPPY BIRTHDAY LETTERS */}
                
                {/*H*/}
                <ParallaxLayer offset={0.15} speed={0.2} style={{ opacity: 1 }}>
                    <img src={h3} style={{ display: 'block', width: '2vw', marginLeft: '9vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={-0.1} style={{ opacity: 1 }}>
                    <img src={h2} style={{ display: 'block', width: '4.5vw', marginLeft: '6vw', marginTop: '1vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={0.1} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '5vw'}} />
                </ParallaxLayer>

                {/**A */}
                
                <ParallaxLayer offset={0.15} speed={0.25} style={{ opacity: 1 }}>
                    <img src={a1} style={{ display: 'block', width: '7.4vw', marginLeft: '11.5vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={-0.05} style={{ opacity: 1 }}>
                    <img src={a2} style={{ display: 'block', width: '7vw', marginLeft: '11.7vw'}} />
                </ParallaxLayer>

                {/* P*/}
                <ParallaxLayer offset={0.15} speed={0.34} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '19.5vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={0.4} style={{ opacity: 1 }}>
                    <img src={p2} style={{ display: 'block', width: '6.5vw', marginLeft: '19.6vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={0.05} style={{ opacity: 1 }}>
                    <img src={p3} style={{ display: 'block', width: '2vw', marginLeft: '22vw', marginTop: '2.2vw'}} />
                </ParallaxLayer>

                {/* P*/}
                <ParallaxLayer offset={0.15} speed={-0.1} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '26.5vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={0.2} style={{ opacity: 1 }}>
                    <img src={p4} style={{ display: 'block', width: '6.5vw', marginLeft: '26.6vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={0.1} style={{ opacity: 1 }}>
                    <img src={p3} style={{ display: 'block', width: '2vw', marginLeft: '29vw', marginTop: '2.2vw'}} />
                </ParallaxLayer>

                {/* Y*/}
                <ParallaxLayer offset={0.15} speed={0.15} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '35.55vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={0.4} style={{ opacity: 1 }}>
                    <img src={y1} style={{ display: 'block', width: '7vw', marginLeft: '33.1vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.15} speed={-0.05} style={{ opacity: 1 }}>
                    <img src={p3} style={{ display: 'block', width: '2.5vw', marginLeft: '35.4vw', marginTop: '-0.5vw'}} />
                </ParallaxLayer>

                {/* B*/}

                <ParallaxLayer offset={0.73} speed={0.3} style={{ opacity: 1 }}>
                    <img src={b1} style={{ display: 'block', width: '3vw', marginLeft: '37.2vw', marginTop: '0.05vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={-0.02} style={{ opacity: 1 }}>
                    <img src={b2} style={{ display: 'block', width: '3.5vw', marginLeft: '37.5vw', marginTop: '3.2vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.19} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '35.55vw'}} />
                </ParallaxLayer>

                {/* I*/}

                <ParallaxLayer offset={0.73} speed={0.21} style={{ opacity: 1 }}>
                    <img src={i1} style={{ display: 'block', width: '2vw', marginLeft: '41.55vw'}} />
                </ParallaxLayer>

                {/* R */}
                
                <ParallaxLayer offset={0.73} speed={0.45} style={{ opacity: 1 }}>
                    <img src={r1} style={{ display: 'block', width: '3.6vw', marginLeft: '46vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={-0.02} style={{ opacity: 1 }}>
                    <img src={r2} style={{ display: 'block', width: '3.8vw', marginLeft: '46.2vw', marginTop: '1.4vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.5} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '44.55vw'}} />
                </ParallaxLayer>

                {/* T */}

                <ParallaxLayer offset={0.73} speed={0.05} style={{ opacity: 1 }}>
                    <img src={t2} style={{ display: 'block', width: '2vw', marginLeft: '52.7vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.3} style={{ opacity: 1 }}>
                    <img src={t1} style={{ display: 'block', width: '6.5vw', marginLeft: '50.5vw'}} />
                </ParallaxLayer>

                {/* H */}

                <ParallaxLayer offset={0.73} speed={0.05} style={{ opacity: 1 }}>
                    <img src={t3} style={{ display: 'block', width: '2vw', marginLeft: '61.3vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.2} style={{ opacity: 1 }}>
                    <img src={h2} style={{ display: 'block', width: '4.5vw', marginLeft: '58.3vw', marginTop: '1vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.3} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '57.5vw'}} />
                </ParallaxLayer>

                {/** D */}

                <ParallaxLayer offset={0.73} speed={-0.05} style={{ opacity: 1 }}>
                    <img src={d1} style={{ display: 'block', width: '5.36vw', marginLeft: '64.6vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.2} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '64vw'}} />
                </ParallaxLayer>

                {/**A */}
                
                <ParallaxLayer offset={0.73} speed={0.15} style={{ opacity: 1 }}>
                    <img src={a3} style={{ display: 'block', width: '7.4vw', marginLeft: '70vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.25} style={{ opacity: 1 }}>
                    <img src={a2} style={{ display: 'block', width: '7vw', marginLeft: '70.2vw'}} />
                </ParallaxLayer>

                {/* Y*/}
                <ParallaxLayer offset={0.73} speed={0.2} style={{ opacity: 1 }}>
                    <img src={h1} style={{ display: 'block', width: '2vw', marginLeft: '79.55vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.4} style={{ opacity: 1 }}>
                    <img src={y2} style={{ display: 'block', width: '7vw', marginLeft: '77.1vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.73} speed={0.15} style={{ opacity: 1 }}>
                    <img src={p3} style={{ display: 'block', width: '2.5vw', marginLeft: '79.4vw', marginTop: '-0.5vw'}} />
                </ParallaxLayer>


                <ParallaxLayer
                    offset={0}
                    speed={0.09}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-8vh'}}>
                    <img src={main} style={{ width: '45vh' }} />
                </ParallaxLayer>

                <ParallaxLayer
                    offset={0}
                    speed={0.3}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-8vh' }}>
                    <img src={hat} style={{ width: '45vh' }} />
                </ParallaxLayer>

                {/** fl1 */}

                <ParallaxLayer
                    offset={0.25}
                    speed={0.3}
                    style={{  }}>
                    <img src={fl1} style={{ display: 'block', width: '8vw', marginLeft: '69vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.25} speed={0.5} style={{ opacity: 0.95 }}>
                <img src={cloud} style={{ display: 'block', width: '12vw', marginLeft: '64.5vw', marginTop:'5vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.25} speed={0.2} style={{ opacity: 0.9 }}>
                <img src={cloud} style={{ display: 'block', width: '12vw', marginLeft: '71.2vw', marginTop:'4vw', transform: 'scaleX(-1)'}} />
                </ParallaxLayer>

                
                {/** fl2 */}

                <ParallaxLayer
                    offset={0.45}
                    speed={0.3}
                    style={{  }}>
                    <img src={fl2} style={{ display: 'block', width: '8vw', marginLeft: '19vw' }} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.45} speed={0.5} style={{ opacity: 0.95 }}>
                <img src={cloud} style={{ display: 'block', width: '12vw', marginLeft: '14.5vw', marginTop:'5vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.45} speed={0.2} style={{ opacity: 0.9 }}>
                <img src={cloud} style={{ display: 'block', width: '12vw', marginLeft: '21.2vw', marginTop:'4vw', transform: 'scaleX(-1)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={0.4} speed={0.34} style={{ opacity: 0.9 }}>
                <img className="floating2" src={cake} style={{ display: 'block', width: '8vw', marginLeft: '81.2vw', marginTop:'4vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={0.69} speed={0.9} style={{ opacity: 0.8 }}>
                <img src={age} className='floating3'  style={{ display: 'block', width: '15vw', marginLeft: '16vw', transform: 'rotate(9deg)'}} />
                </ParallaxLayer>


                {/** floating gallery */}
                
                <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g69} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '84vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={1} speed={0.25} style={{ opacity: 0.9}}>
                <img src={g1} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '2vw', transform: 'rotate(90deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={1.05} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g11} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '45vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={1.4} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g21} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '86vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                
                <ParallaxLayer offset={2} speed={-0.1} style={{ opacity: 0.9}}>
                <img src={g31} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '15vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={2.1} speed={-0.0} style={{ opacity: 0.9}}>
                <img src={g41} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '65vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={2.6} speed={-0.0} style={{ opacity: 0.9}}>
                <img src={g51} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '85vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={2.6} speed={-0.0} style={{ opacity: 0.9}}>
                <img src={g70} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={2.9} speed={-0.1} style={{ opacity: 0.9}}>
                <img src={g71} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '8vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={2.9} speed={-0.0} style={{ opacity: 0.9}}>
                <img src={g61} style={{ borderRadius:'10%', display: 'block', width: '8vw', marginLeft: '65vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={3.5} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g30} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '85vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={3.8} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g16} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={3.85} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g19} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '80vw', transform: 'rotate(90deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={4.1} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g23} style={{ borderRadius:'10%', display: 'block', width: '8vw', marginLeft: '2vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={4.3} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g55} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '85vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={4.8} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g37} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={4.75} speed={0.45} style={{ opacity: 0.9}}>
                <img src={g46} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '55vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={4.95} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g27} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '85vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={5} speed={0.15} style={{ opacity: 0.9}}>
                <img src={g39} style={{ borderRadius:'10%', display: 'block', width: '10vw', marginLeft: '35vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={5.1} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g63} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '85vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={5.15} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g6} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={5.6} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g29} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '85vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={6} speed={-0.1} style={{ opacity: 0.9}}>
                <img src={g8} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={6.05} speed={-0.1} style={{ opacity: 0.9}}>
                <img src={g72} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '60vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={5.9} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g26} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '83vw', marginTop:'8vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={6.6} speed={-0.1} style={{ opacity: 0.9}}>
                <img src={g7} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '2vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={6.3} speed={0.25} style={{ opacity: 0.9}}>
                <img src={g20} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '80vw', transform: 'rotate(90deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={6.9} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g3} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '78vw', transform: 'rotate(90deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={7.6} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g38} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={7.4} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g48} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '49vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={7.3} speed={0.05} style={{ opacity: 0.9}}>
                <img src={g67} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '82vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={7.9} speed={0.25} style={{ opacity: 0.9}}>
                <img src={g28} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '82vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={8.4} speed={0.55} style={{ opacity: 0.9}}>
                <img src={g18} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '55vw', transform: 'rotate(90deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={8} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g75} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '88vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={8.4} speed={-0.05} style={{ opacity: 0.9}}>
                <img src={g53} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '82vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={8} speed={0.35} style={{ opacity: 0.9}}>
                <img src={g78} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '2vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={8.4} speed={0.25} style={{ opacity: 0.9}}>
                <img src={g13} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '8vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={8.9} speed={0.35} style={{ opacity: 0.9}}>
                <img src={g22} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '78vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9} speed={0.35} style={{ opacity: 0.9}}>
                <img src={g81} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '42vw', marginTop:'-2vw', transform: 'rotate(-90deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g86} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '70vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={8.6} speed={0.15} style={{ opacity: 0.9}}>
                <img src={g68} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '1vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.1} speed={0.15} style={{ opacity: 0.9}}>
                <img src={g5} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', transform: 'rotate(90deg)'}} />
                </ParallaxLayer>


                <ParallaxLayer offset={9.4} speed={0.05} style={{ opacity: 0.9}}>
                <img src={g25} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '83vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.6} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g36} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', transform: 'rotate(-90deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.8} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g50} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '25vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.9} speed={-0.05} style={{ opacity: 0.9}}>
                <img src={g32} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '85vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.1} speed={-0.1} style={{ opacity: 0.9}}>
                <img src={g66} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '4vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.45} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g56} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '68vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.6} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g87} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '3vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.9} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g90} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '73vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g88} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '65vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g12} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '86vw', marginTop:'5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.1} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g33} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '18vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.6} speed={0.5} style={{ opacity: 0.9}}>
                <img src={g74} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.9} speed={0.15} style={{ opacity: 0.9}}>
                <img src={g17} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '12vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g24} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '35vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12} speed={0.4} style={{ opacity: 0.9}}>
                <img src={g59} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '75vw', marginTop:'-2vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.2} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g64} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '5vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.4} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g14} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '83vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.3} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g60} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '60vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.6} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g62} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '2vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12.9} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g84} style={{ borderRadius:'10%', display: 'block', width: '9vw', marginLeft: '82vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g2} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '55vw', marginTop:'0vw', transform: 'rotate(-90deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.1} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g4} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '15vw', marginTop:'0vw', transform: 'rotate(90deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.9} speed={0.2} style={{ opacity: 0.9}}>
                <img src={g40} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '85vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.9} speed={0.3} style={{ opacity: 0.9}}>
                <img src={g34} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '15vw', marginTop:'10vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={14} speed={0.25} style={{ opacity: 0.9}}>
                <img src={g44} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '2vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={14} speed={0.1} style={{ opacity: 0.9}}>
                <img src={g47} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '72vw', marginTop:'0vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={14} speed={-0.1} style={{ opacity: 0.9}}>
                <img src={g42} style={{ borderRadius:'10%', display: 'block', width: '12vw', marginLeft: '45vw', marginTop:'-1vw', transform: 'rotate(0deg)'}} />
                </ParallaxLayer>


                {/** WISHES */}

                <ParallaxLayer offset={1.2} speed={0.4} style={{ opacity: 0.95 }}>
                <img src={wishAva} style={{ display: 'block', width: '85vw', marginLeft: '6vw', marginTop:'4vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={1.3} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '12vw', marginLeft: '21.2vw', marginTop:'4vw', transform: 'scaleX(-1)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={1.3} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.5 }}>
                <img src={cloud} style={{ display: 'block', width: '16vw', marginLeft: '2vw', marginTop:'32vw'}} />
                </ParallaxLayer>


                {/** WISHES */}
                <ParallaxLayer offset={2} speed={0.3} style={{ opacity: 0.95 }}>
                <img src={wishAngela} style={{ display: 'block', width: '70vw', marginLeft: '14vw', marginTop:'4vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={2} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.45 }}>
                <img src={cloud} style={{ display: 'block', width: '19vw', marginLeft: '60vw', marginTop:'32vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={2.2} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={purpleb2} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '2vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                
                {/** WISHES */}
                <ParallaxLayer offset={2.9} speed={0.4} style={{ opacity: 0.95 }}>
                <img src={wishSonila} style={{ display: 'block', width: '75vw', marginLeft: '10vw', marginTop:'12vw'}} />
                </ParallaxLayer>
                <ParallaxLayer offset={3} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.7 }}>
                <img src={cloud} style={{ display: 'block', width: '12vw', marginLeft: '15vw', marginTop:'0vw'}} />
                </ParallaxLayer>
                <ParallaxLayer offset={2.8} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={yellowb} className='floating1'  style={{ display: 'block', width: '6vw', marginLeft: '70vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                <ParallaxLayer offset={3.2} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={redb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '2vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={3.3} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={blueb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '90vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                

                
                {/** WISHES */}
                <ParallaxLayer offset={3.5} speed={0.2} style={{ opacity: 0.95 }}>
                <img src={wishFahad} style={{ display: 'block', width: '75vw', marginLeft: '10vw', marginTop:'4vw'}} />
                </ParallaxLayer>
                <ParallaxLayer offset={4} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.55 }}>
                <img src={cloud} style={{ display: 'block', width: '22vw', marginLeft: '55vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={4} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={salmonb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '90vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={3.5} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={orangeb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '9vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                
                {/** WISHES */}
                <ParallaxLayer offset={4} speed={0.34} style={{ opacity: 0.95 }}>
                <img src={wishKathryn} style={{ display: 'block', width: '75vw', marginLeft: '10vw', marginTop:'4vw'}} />
                </ParallaxLayer>
                <ParallaxLayer offset={4.1} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.55 }}>
                <img src={cloud} style={{ display: 'block', width: '11vw', marginLeft: '15vw', marginTop:'0vw'}} />
                </ParallaxLayer>


                
                {/** WISHES */}
                <ParallaxLayer offset={4.9} speed={0.38} style={{ opacity: 0.95 }}>
                <img src={wishJana} style={{ display: 'block', width: '90vw', marginLeft: '15vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={5.1} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.55 }}>
                <img src={cloud} style={{ display: 'block', width: '13vw', marginLeft: '75vw', marginTop:'0vw'}} />
                </ParallaxLayer>
                
                <ParallaxLayer offset={4.7} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={pinkb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '71vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                <ParallaxLayer offset={5} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={limeb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '9vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                
                {/** WISHES */}
                <ParallaxLayer offset={5.4} speed={0.34} style={{ opacity: 0.95 }}>
                <img src={wishAhmad} style={{ display: 'block', width: '75vw', marginLeft: '10vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={5.6} speed={(Math.random() * (-0.3001 - 0.8001) + 0.80001).toFixed(4)} style={{ opacity: 0.55 }}>
                <img src={cloud} style={{ display: 'block', width: '13vw', marginLeft: '75vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={5.8} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.8 }}>
                <img src={tealb2} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '88vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                <ParallaxLayer offset={5.3} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.75 }}>
                <img src={purpleb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '55vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                {/** WISHES */}
                <ParallaxLayer offset={6} speed={0.4} style={{ opacity: 0.95 }}>
                <img src={wishCarmen} style={{ display: 'block', width: '75vw', marginLeft: '10vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                {/** WISHES */}
                <ParallaxLayer offset={6.9} speed={0.35} style={{ opacity: 0.95 }}>
                <img src={wishVenky} style={{ display: 'block', width: '75vw', marginLeft: '11vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                {/** WISHES */}
                <ParallaxLayer offset={7.2} speed={0.35} style={{ opacity: 0.95 }}>
                <img src={wishFaizan} style={{ display: 'block', width: '75vw', marginLeft: '15vw', marginTop:'0vw'}} />
                </ParallaxLayer>
                
                {/** WISHES */}
                <ParallaxLayer offset={7.9} speed={0.45} style={{ opacity: 0.95 }}>
                <img src={wishFreshta} style={{ display: 'block', width: '75vw', marginLeft: '10vw', marginTop:'0vw'}} />
                </ParallaxLayer>
                
                {/** WISHES */}
                <ParallaxLayer offset={8.6} speed={0.25} style={{ opacity: 0.95 }}>
                <img src={wishRach} style={{ display: 'block', width: '75vw', marginLeft: '8vw', marginTop:'0vw'}} />
                </ParallaxLayer>
                
                {/** WISHES */}
                <ParallaxLayer offset={9.2} speed={0.4} style={{ opacity: 0.95 }}>
                <img src={wishSoda} style={{ display: 'block', width: '75vw', marginLeft: '15vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                {/** WISHES */}
                <ParallaxLayer offset={9.8} speed={0.3} style={{ opacity: 0.95 }}>
                <img src={wishJoy} style={{ display: 'block', width: '75vw', marginLeft: '8vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                {/** WISHES */}
                <ParallaxLayer offset={10.4} speed={0.3} style={{ opacity: 0.95 }}>
                <img src={wishSaatvik} style={{ display: 'block', width: '75vw', marginLeft: '8vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                {/** WISHES */}
                <ParallaxLayer offset={11.3} speed={-0.05} style={{ opacity: 0.95 }}>
                <img src={wishMomDad} style={{ display: 'block', width: '85vw', marginLeft: '11vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                {/** WISHES */}
                <ParallaxLayer offset={12.3} speed={0.4} style={{ opacity: 0.95 }}>
                <img src={wishNanaji} style={{ display: 'block', width: '95vw', marginLeft: '2vw', marginTop:'0vw'}} />
                </ParallaxLayer>

                
                {/**(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4) */}

                {/** WISHES */}

                <ParallaxLayer offset={13.2} speed={0.3} style={{ opacity: 0.95 }}>
                <img src={wishKali} style={{ display: 'block', width: '70vw', marginLeft: '10vw', marginTop:'4vw'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.3} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.2 }}>
                <img src={cloud} style={{ display: 'block', width: '12vw', marginLeft: '21.2vw', marginTop:'4vw', transform: 'scaleX(-1)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.3} speed={(Math.random() * (-0.05001 - 0.5001) + 0.50001).toFixed(4)} style={{ opacity: 0.7 }}>
                <img src={cloud} style={{ display: 'block', width: '16vw', marginLeft: '2vw', marginTop:'32vw'}} />
                </ParallaxLayer>
                

                {/** BALLOON */}

                <ParallaxLayer offset={7} speed={0.1} style={{ opacity: 0.8 }}>
                <img src={limeb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '9vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={6.9} speed={0.35} style={{ opacity: 0.8 }}>
                <img src={tealb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '88vw', marginTop:'10vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={7.5} speed={0.3} style={{ opacity: 0.8 }}>
                <img src={blueb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '88vw', marginTop:'10vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                

                <ParallaxLayer offset={8.15} speed={0.05} style={{ opacity: 0.8 }}>
                <img src={yellowb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '30vw', marginTop:'10vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                

                <ParallaxLayer offset={9} speed={0.2} style={{ opacity: 0.8 }}>
                <img src={redb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '3vw', marginTop:'-2vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                

                <ParallaxLayer offset={9} speed={0.1} style={{ opacity: 0.8 }}>
                <img src={orangeb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '60vw', marginTop:'5vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.1} speed={0.3} style={{ opacity: 0.8 }}>
                <img src={purpleb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '80vw', marginTop:'5vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={9.6} speed={0.1} style={{ opacity: 0.8 }}>
                <img src={limeb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '40vw', marginTop:'5vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.1} speed={0.4} style={{ opacity: 0.8 }}>
                <img src={tealb2} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '88vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.3} speed={0.2} style={{ opacity: 0.8 }}>
                <img src={pinkb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '32vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.7} speed={0.4} style={{ opacity: 0.8 }}>
                <img src={greenb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '88vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={10.9} speed={0.3} style={{ opacity: 0.8 }}>
                <img src={salmonb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '62vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11} speed={0.3} style={{ opacity: 0.8 }}>
                <img src={redb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '5vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.6} speed={0.1} style={{ opacity: 0.8 }}>
                <img src={blueb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '88vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={11.9} speed={0.2} style={{ opacity: 0.8 }}>
                <img src={orangeb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '8vw', marginLeft: '40vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={12} speed={0.2} style={{ opacity: 0.8 }}>
                <img src={yellowb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '20vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.8} speed={0.3} style={{ opacity: 0.8 }}>
                <img src={greenb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '2vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13} speed={0.1} style={{ opacity: 0.8 }}>
                <img src={tealb2} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '5vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>

                <ParallaxLayer offset={13.3} speed={0.2} style={{ opacity: 0.8 }}>
                <img src={pinkb} className={'floating'+(Math.floor(Math.random() * Math.floor(3)) + 1)}  style={{ display: 'block', width: '6vw', marginLeft: '85vw', marginTop:'0vw', transform: 'rotate(-2deg)'}} />
                </ParallaxLayer>
                
                {/** */}

                <ParallaxLayer
                    offset={14}
                    speed={0.09}
                    style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'center',  opacity: 0.9 }}>
                    <img src={back} style={{display: 'block', width: '100vw'}} />
                </ParallaxLayer>
                
                <ParallaxLayer
                    offset={14}
                    speed={0.09}
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center',  opacity: 0.9 }}>
                    <img src={love} style={{display: 'block', width: '95vw' }} />
                </ParallaxLayer>
                
                <ParallaxLayer
                    offset={14}
                    speed={0.09}
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center',  opacity: 0.9 }}>
                    <img src={love2} style={{display: 'block', width: '35vw' }} />
                </ParallaxLayer>


                <ParallaxLayer style={{margin:'1vw'}}  offset={0.0} speed={-1}>
                    {this.state.play ? 
                        <IconButton onClick={this.pause} size='medium'>
                            <PauseCircleFilledIcon  style={{fill: "rgb(41, 118, 218)", width:45, height:45}}/>
                        </IconButton>
                    :
                        <IconButton onClick={this.play} size='medium'>
                            <PlayCircleFilledIcon style={{fill: "#eb4d9c", width:45, height:45}}/>
                        </IconButton>
                    }
                </ParallaxLayer>

                
            </Parallax>
        )
    }
}

export default App;
