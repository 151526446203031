import other1 from '../../media/2021/other/other1.jpg';
import other2 from '../../media/2021/other/other2.jpeg';
import other3 from '../../media/2021/other/other3.jpg';
import other4 from '../../media/2021/other/other4.gif';
import other5 from '../../media/2021/other/other5.png';
import other6 from '../../media/2021/other/other6.png';
import other7 from '../../media/2021/other/other7.png';
import other8 from '../../media/2021/other/other8.jpg';
import other9 from '../../media/2021/other/other9.jpeg';
import other10 from '../../media/2021/other/other10.jpg';
import other11 from '../../media/2021/other/other11.jpeg';
import other12 from '../../media/2021/other/other12.jpg';
import other13 from '../../media/2021/other/other13.jpg';
import other14 from '../../media/2021/other/other14.jpg';
import other15 from '../../media/2021/other/other15.jpg';
import other16 from '../../media/2021/other/other16.jpeg';
import other17 from '../../media/2021/other/other17.jpg';
import other18 from '../../media/2021/other/other18.jpg';
import other19 from '../../media/2021/other/other19.jpg';
import other20 from '../../media/2021/other/other20.jpeg';
import other21 from '../../media/2021/other/other21.jpg';
import other22 from '../../media/2021/other/other22.jpeg';
import other23 from '../../media/2021/other/other23.jpeg';
import other24 from '../../media/2021/other/other24.jpg';
import other25 from '../../media/2021/other/other25.jpg';
import other26 from '../../media/2021/other/other26.gif';
import other27 from '../../media/2021/other/other27.png';
import other28 from '../../media/2021/other/other28.JPG';
import other29 from '../../media/2021/other/other29.jpg';
import other30 from '../../media/2021/other/other30.jpeg';
import other31 from '../../media/2021/other/other31.jpeg';
import other32 from '../../media/2021/other/other32.jpeg';
import other33 from '../../media/2021/other/other33.jpeg';
import other34 from '../../media/2021/other/other34.jpeg';
import other35 from '../../media/2021/other/other35.jpeg';
import other36 from '../../media/2021/other/other36.jpeg';
import other37 from '../../media/2021/other/other37.jpeg';
import other38 from '../../media/2021/other/other38.jpeg';
import other39 from '../../media/2021/other/other39.jpg';
import other40 from '../../media/2021/other/other40.jpeg';
import other41 from '../../media/2021/other/other41.jpeg';
import other42 from '../../media/2021/other/other42.jpeg';
import other43 from '../../media/2021/other/other43.jpeg';
import other44 from '../../media/2021/other/other44.jpeg';
import other45 from '../../media/2021/other/other45.jpeg';
import other46 from '../../media/2021/other/other46.jpeg';
import other47 from '../../media/2021/other/other47.jpeg';
import other48 from '../../media/2021/other/other48.jpeg';
import other49 from '../../media/2021/other/other49.jpg';
import other50 from '../../media/2021/other/other50.jpg';
import other51 from '../../media/2021/other/other51.jpg';
import other52 from '../../media/2021/other/other52.jpg';
import other53 from '../../media/2021/other/other53.jpg';
import other54 from '../../media/2021/other/other54.jpg';
import other55 from '../../media/2021/other/other55.jpg';
import other56 from '../../media/2021/other/other56.jpg';
import other57 from '../../media/2021/other/other57.jpg';
import other58 from '../../media/2021/other/other58.jpg';
import other59 from '../../media/2021/other/other59.jpg';
import other60 from '../../media/2021/other/other60.jpg';
import other61 from '../../media/2021/other/other61.jpg';
import other62 from '../../media/2021/other/other62.jpg';
import other63 from '../../media/2021/other/other63.jpg';
import other64 from '../../media/2021/other/other64.jpg';
import other65 from '../../media/2021/other/other65.jpg';
import other66 from '../../media/2021/other/other66.jpg';
import other67 from '../../media/2021/other/other67.jpg';
import other68 from '../../media/2021/other/other68.jpg';
import other69 from '../../media/2021/other/other69.jpg';
import other70 from '../../media/2021/other/other70.png';

export const images = [
    other1,
    other2,
    other3,
    other4,
    other5,
    other6,
    other7,
    other8,
    other9,
    other10,
    other11,
    other12,
    other13,
    other14,
    other15,
    other16,
    other17,
    other18,
    other19,
    other20,
    other21,
    other22,
    other23,
    other24,
    other25,
    other26,
    other27,
    other28,
    other29,
    other30,
    other31,
    other32,
    other33,
    other34,
    other35,
    other36,
    other37,
    other38,
    other39,
    other40,
    other41,
    other42,
    other43,
    other44,
    other45,
    other46,
    other47,
    other48,
    other49,
    other50,
    other51,
    other52,
    other53,
    other54,
    other55,
    other56,
    other57,
    other58,
    other59,
    other60,
    other61,
    other62,
    other63,
    other64,
    other65,
    other66,
    other67,
    other68,
    other69,
    other70
];
  